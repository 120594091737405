//
// _helper.scss
//

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

// Font weight help class

.fw-medium {
  font-weight: $fw-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.text-black {
  color: $black;
}

.shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: none !important;
  outline: none !important;
}

.text-right {
  text-align: right;
}

.bg-white {
  background-color: $white !important;
}
// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;
  &:hover {
    color: $gray-600;
    background-color: $gray-200;
  }
}
.mv-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

// media

.flex-1 {
  flex: 1;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

// alert

.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg-dark) center /
      $btn-close-width auto no-repeat;
  }
}
.color-green {
  color: $green;
}

.color-secondary {
  color: $secondary;
}

.color-primary {
  color: $primary;
}

.pt-12 {
  padding-top: 12px;
}

.mt-12 {
  margin-top: 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.error-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f46a6a;
}

.p-0 {
  padding: 0 !important;
}

.pv-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ph-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.m-12 {
  margin: 12px !important;
}

.mh-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.m-20 {
  margin: 20px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.mr-6 {
  margin-right: 6px;
}

.ml-6 {
  margin-left: 6px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.w-full {
  width: 100%;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.text-normal {
  text-transform: initial;
}
